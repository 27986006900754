import React, { lazy, Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "react-loading-skeleton/dist/skeleton.css";
import "./components/Portal/Layout/layout.css";
import "bootstrap/dist/css/bootstrap.css";
import { getCookie } from "./components/Common/getCookie";
import { setCookie } from "./components/Common/setCookie";
import LoginFallback from "./components/Portal/Login/LoginFallback";

const Dashboard = lazy(() => import("./components/Portal/Feedback/Dashboard/Dashboard"));
const PortalLayout = lazy(() => import('./components/Portal/Layout/PortalLayout'));

// Convert direct imports to lazy loaded components
const Register = lazy(() => import("./components/Portal/Login/Register"));
const Heatmap = lazy(() => import("./components/Portal/Feedback/Heatmap/Heatmap"));

// Roles related components
const Roles = lazy(() => import("./components/Portal/Roles/Roles"));
const CreateRole = lazy(() => import("./components/Portal/Roles/CreateRole"));
const EditRole = lazy(() => import("./components/Portal/Roles/EditRole"));

// Locations related components
const Locations = lazy(() => import("./components/Portal/Locations/Locations"));
const EditLocations = lazy(() => import("./components/Portal/Locations/EditLocations"));
const CreateLocation = lazy(() => import("./components/Portal/Locations/CreateLocation"));

// Disciplines related components
const Disciplines = lazy(() => import("./components/Portal/Disciplines/Disciplines"));
const EditDisciplines = lazy(() => import("./components/Portal/Disciplines/EditDisciplines"));
const CreateDiscipline = lazy(() => import("./components/Portal/Disciplines/CreateDiscipline"));

const EvaluationSurveys = lazy(() =>
  import("./components/Portal/Feedback/Evaluation/EvaluationSurveys")
);
const EvaluationQuestions = lazy(() =>
  import("./components/Portal/Feedback/Evaluation/EvaluationQuestions")
);
const NoPermission = lazy(() =>
  import("./components/Portal/NoPermission/NoPermission")
);
const Launch = lazy(() => import("./components/Portal/Login/Launch"));
const Login = lazy(() => import("./components/Portal/Login/Login"));
const PasswordReset = lazy(() =>
  import("./components/Portal/Login/PasswordReset")
);
const PasswordResetConfirm = lazy(() =>
  import("./components/Portal/Login/PasswordResetConfirm")
);
const CreateNewUser = lazy(() =>
  import("./components/Portal/Login/CreateNewUser")
);
const Pending = lazy(() => import("./components/Portal/Login/Pending"));
const Survey = lazy(() => import("./components/Survey/Survey"));

const Boards = lazy(() => import("./components/Portal/Boards/Boards"));
const CreateBoard = lazy(() =>
  import("./components/Portal/Boards/CreateBoard")
);
const EditBoard = lazy(() => import("./components/Portal/Boards/EditBoards"));

const Profile = lazy(() => import("./components/Portal/Profile/Profile"));

const FeedbackLink = lazy(() =>
  import("./components/Portal/Link/FeedbackLink")
);
const Courses = lazy(() => import("./components/Portal/Courses/Courses"));
const EditCourses = lazy(() =>
  import("./components/Portal/Courses/EditCourses")
);
const CreateCourse = lazy(() =>
  import("./components/Portal/Courses/CreateCourse")
);

const Users = lazy(() => import("./components/Portal/Users/Users"));
const CreateUser = lazy(() => import("./components/Portal/Users/CreateUser"));
const EditUser = lazy(() => import("./components/Portal/Users/EditUser"));

const LookAndFeel = lazy(() =>
  import("./components/Portal/Settings/LookAndFeel")
);
const EvaluationOptions = lazy(() =>
  import("./components/Portal/Settings/EvaluationOptions")
);
const Automation = lazy(() =>
  import("./components/Portal/Automation/Automation")
);
const CreateAutomation = lazy(() =>
  import("./components/Portal/Automation/CreateAutomation")
);
const EditAutomation = lazy(() =>
  import("./components/Portal/Automation/EditAutomation")
);
const Categories = lazy(() =>
  import("./components/Portal/Categories/Categories")
);
const EditCategories = lazy(() =>
  import("./components/Portal/Categories/EditCategories")
);
const CreateCategory = lazy(() =>
  import("./components/Portal/Categories/CreateCategory")
);

const SSO = lazy(() => import("./components/Portal/SSO/SSO"));
const EditSSO = lazy(() => import("./components/Portal/SSO/EditSSO"));
const CreateSSO = lazy(() => import("./components/Portal/SSO/CreateSSO"));

const Texts = lazy(() => import("./components/Portal/Settings/Texts"));
const Questions = lazy(() => import("./components/Portal/Questions/Questions"));
const EditQuestions = lazy(() =>
  import("./components/Portal/Questions/EditQuestions")
);
const CreateQuestions = lazy(() =>
  import("./components/Portal/Questions/CreateQuestions")
);
const Surveys = lazy(() => import("./components/Portal/Surveys/Surveys"));
const EditSurveys = lazy(() =>
  import("./components/Portal/Surveys/EditSurveys")
);
const CreateSurvey = lazy(() =>
  import("./components/Portal/Surveys/CreateSurvey")
);
const Activities = lazy(() =>
  import("./components/Portal/Settings/Activities")
);
const PermissionWrapper = lazy(() =>
  import("./components/Common/PermissionWrapper")
);
const CommentsPage = lazy(() =>
  import("./components/Portal/Feedback/Comments/CommentsPage")
);

const App = () => {
  document.addEventListener("click", function (event) {
    const expirationDate = new Date(Date.now() + 86400 * 1000); // Set the expiration date to 1 day from the current time
    const jwt = getCookie("jwt");
    setCookie("jwt", jwt, expirationDate);
  });

  return (
    <BrowserRouter>
      <Routes>
        {/* Public routes */}
        <Route path="*" element={<Launch />} />
        <Route path="/" element={<Launch />} />
        <Route path="/login" element={<Login />} />
        <Route 
          path="/register" 
          element={
            <Suspense fallback={<LoginFallback />}>
              <Register />
            </Suspense>
          } 
        />
        <Route path="/pending" element={<Pending />} />
        <Route path="/newuser" element={<CreateNewUser />} />
        <Route path="/passwordreset" element={<PasswordReset />} />
        <Route
          path="/passwordresetconfirm"
          element={<PasswordResetConfirm />}
        />
        <Route path="/survey" element={<Survey />} />

        {/* Protected routes */}
        <Route element={<PortalLayout />}>
          <Route path="/nopermission" element={<NoPermission />} />
          <Route
            path="/dashboard"
            element={
              <Suspense fallback={<LoginFallback />}>
                <PermissionWrapper requiredPermission={1}>
                  <Dashboard />
                </PermissionWrapper>
              </Suspense>
            }
          />
          <Route path="/boards" element={<Boards />} />
          <Route path="/boards/create" element={<CreateBoard />} />
          <Route path="/boards/edit/:boardId" element={<EditBoard />} />
          <Route
            path="/evaluation/questions"
            element={
              <Suspense fallback={<LoginFallback />}>
                <PermissionWrapper requiredPermission={1}>
                  <EvaluationQuestions />
                </PermissionWrapper>
              </Suspense>
            }
          />
          <Route
            path="/evaluation/questions/:Id"
            element={
              <Suspense fallback={<LoginFallback />}>
                <PermissionWrapper requiredPermission={1}>
                  <EvaluationQuestions />
                </PermissionWrapper>
              </Suspense>
            }
          />
          <Route
            path="/evaluation/surveys"
            element={
              <Suspense fallback={<LoginFallback />}>
                <PermissionWrapper requiredPermission={1}>
                  <EvaluationSurveys />
                </PermissionWrapper>
              </Suspense>
            }
          />
          <Route
            path="/evaluation/surveys/:Id"
            element={
              <Suspense fallback={<LoginFallback />}>
                <PermissionWrapper requiredPermission={1}>
                  <EvaluationSurveys />
                </PermissionWrapper>
              </Suspense>
            }
          />
          <Route
            path="/comments"
            element={
              <Suspense fallback={<LoginFallback />}>
                <PermissionWrapper requiredPermission={1}>
                  <CommentsPage />
                </PermissionWrapper>
              </Suspense>
            }
          />
          <Route
            path="/heatmap"
            element={
              <Suspense fallback={<LoginFallback />}>
                <PermissionWrapper requiredPermission={1}>
                  <Heatmap />
                </PermissionWrapper>
              </Suspense>
            }
          />
          <Route
            path="/profile"
            element={
              <Suspense fallback={<LoginFallback />}>
                <PermissionWrapper requiredPermission={0}>
                  <Profile />
                </PermissionWrapper>
              </Suspense>
            }
          />
          <Route
            path="/users"
            element={
              <Suspense fallback={<LoginFallback />}>
                <PermissionWrapper requiredPermission={22}>
                  <Users />
                </PermissionWrapper>
              </Suspense>
            }
          />
          <Route
            path="/link"
            element={
              <Suspense fallback={<LoginFallback />}>
                <PermissionWrapper requiredPermission={15}>
                  <FeedbackLink />
                </PermissionWrapper>
              </Suspense>
            }
          />
          <Route
            path="/courses"
            element={
              <Suspense fallback={<LoginFallback />}>
                <PermissionWrapper requiredPermission={17}>
                  <Courses />
                </PermissionWrapper>
              </Suspense>
            }
          />
          <Route
            path="/courses/edit/:courseId"
            element={
              <Suspense fallback={<LoginFallback />}>
                <PermissionWrapper requiredPermission={17}>
                  <EditCourses />
                </PermissionWrapper>
              </Suspense>
            }
          />
          <Route
            path="/courses/create"
            element={
              <Suspense fallback={<LoginFallback />}>
                <PermissionWrapper requiredPermission={17}>
                  <CreateCourse />
                </PermissionWrapper>
              </Suspense>
            }
          />
          <Route
            path="/users/create"
            element={
              <Suspense fallback={<LoginFallback />}>
                <PermissionWrapper requiredPermission={22}>
                  <CreateUser />
                </PermissionWrapper>
              </Suspense>
            }
          />
          <Route
            path="/users/edit/:userId"
            element={
              <Suspense fallback={<LoginFallback />}>
                <PermissionWrapper requiredPermission={22}>
                  <EditUser />
                </PermissionWrapper>
              </Suspense>
            }
          />
          <Route
            path="/lookandfeel"
            element={
              <Suspense fallback={<LoginFallback />}>
                <PermissionWrapper requiredPermission={23}>
                  <LookAndFeel />
                </PermissionWrapper>
              </Suspense>
            }
          />
          <Route
            path="/evaluationoptions"
            element={
              <Suspense fallback={<LoginFallback />}>
                <PermissionWrapper requiredPermission={13}>
                  <EvaluationOptions />
                </PermissionWrapper>
              </Suspense>
            }
          />
          <Route
            path="/automation"
            element={
              <Suspense fallback={<LoginFallback />}>
                <PermissionWrapper requiredPermission={1}>
                  <Automation />
                </PermissionWrapper>
              </Suspense>
            }
          />
          <Route
            path="/automation/create"
            element={
              <Suspense fallback={<LoginFallback />}>
                <PermissionWrapper requiredPermission={1}>
                  <CreateAutomation />
                </PermissionWrapper>
              </Suspense>
            }
          />
          <Route
            path="/automation/edit/:automationId"
            element={
              <Suspense fallback={<LoginFallback />}>
                <PermissionWrapper requiredPermission={1}>
                  <EditAutomation />
                </PermissionWrapper>
              </Suspense>
            }
          />
          <Route
            path="/categories"
            element={
              <Suspense fallback={<LoginFallback />}>
                <PermissionWrapper requiredPermission={10}>
                  <Categories />
                </PermissionWrapper>
              </Suspense>
            }
          />
          <Route
            path="/categories/edit/:categoryId"
            element={
              <Suspense fallback={<LoginFallback />}>
                <PermissionWrapper requiredPermission={10}>
                  <EditCategories />
                </PermissionWrapper>
              </Suspense>
            }
          />
          <Route
            path="/categories/create"
            element={
              <Suspense fallback={<LoginFallback />}>
                <PermissionWrapper requiredPermission={10}>
                  <CreateCategory />
                </PermissionWrapper>
              </Suspense>
            }
          />
          <Route
            path="/locations"
            element={
              <Suspense fallback={<LoginFallback />}>
                <PermissionWrapper requiredPermission={10}>
                  <Locations />
                </PermissionWrapper>
              </Suspense>
            }
          />
          <Route
            path="/locations/create"
            element={
              <Suspense fallback={<LoginFallback />}>
                <PermissionWrapper requiredPermission={10}>
                  <CreateLocation />
                </PermissionWrapper>
              </Suspense>
            }
          />
          <Route
            path="/locations/edit/:locationId"
            element={
              <Suspense fallback={<LoginFallback />}>
                <PermissionWrapper requiredPermission={10}>
                  <EditLocations />
                </PermissionWrapper>
              </Suspense>
            }
          />
          <Route
            path="/disciplines"
            element={
              <Suspense fallback={<LoginFallback />}>
                <PermissionWrapper requiredPermission={10}>
                  <Disciplines />
                </PermissionWrapper>
              </Suspense>
            }
          />
          <Route
            path="/disciplines/create"
            element={
              <Suspense fallback={<LoginFallback />}>
                <PermissionWrapper requiredPermission={10}>
                  <CreateDiscipline />
                </PermissionWrapper>
              </Suspense>
            }
          />
          <Route
            path="/disciplines/edit/:disciplineId"
            element={
              <Suspense fallback={<LoginFallback />}>
                <PermissionWrapper requiredPermission={10}>
                  <EditDisciplines />
                </PermissionWrapper>
              </Suspense>
            }
          />
          <Route
            path="/roles"
            element={
              <Suspense fallback={<LoginFallback />}>
                <PermissionWrapper requiredPermission={21}>
                  <Roles />
                </PermissionWrapper>
              </Suspense>
            }
          />
          <Route
            path="/roles/create"
            element={
              <Suspense fallback={<LoginFallback />}>
                <PermissionWrapper requiredPermission={21}>
                  <CreateRole />
                </PermissionWrapper>
              </Suspense>
            }
          />
          <Route
            path="/roles/edit/:roleId"
            element={
              <Suspense fallback={<LoginFallback />}>
                <PermissionWrapper requiredPermission={21}>
                  <EditRole />
                </PermissionWrapper>
              </Suspense>
            }
          />
          <Route
            path="/sso"
            element={
              <Suspense fallback={<LoginFallback />}>
                <PermissionWrapper requiredPermission={20}>
                  <SSO />
                </PermissionWrapper>
              </Suspense>
            }
          />
          <Route
            path="/sso/edit/:ssoId"
            element={
              <Suspense fallback={<LoginFallback />}>
                <PermissionWrapper requiredPermission={20}>
                  <EditSSO />
                </PermissionWrapper>
              </Suspense>
            }
          />
          <Route
            path="/sso/create"
            element={
              <Suspense fallback={<LoginFallback />}>
                <PermissionWrapper requiredPermission={20}>
                  <CreateSSO />
                </PermissionWrapper>
              </Suspense>
            }
          />
          <Route
            path="/texts"
            element={
              <Suspense fallback={<LoginFallback />}>
                <PermissionWrapper requiredPermission={14}>
                  <Texts />
                </PermissionWrapper>
              </Suspense>
            }
          />
          <Route
            path="/questions"
            element={
              <Suspense fallback={<LoginFallback />}>
                <PermissionWrapper requiredPermission={16}>
                  <Questions />
                </PermissionWrapper>
              </Suspense>
            }
          />
          <Route
            path="/questions/edit/:questionId"
            element={
              <Suspense fallback={<LoginFallback />}>
                <PermissionWrapper requiredPermission={16}>
                  <EditQuestions />
                </PermissionWrapper>
              </Suspense>
            }
          />
          <Route
            path="/questions/create"
            element={
              <Suspense fallback={<LoginFallback />}>
                <PermissionWrapper requiredPermission={16}>
                  <CreateQuestions />
                </PermissionWrapper>
              </Suspense>
            }
          />
          <Route
            path="/surveys"
            element={
              <Suspense fallback={<LoginFallback />}>
                <PermissionWrapper requiredPermission={11}>
                  <Surveys />
                </PermissionWrapper>
              </Suspense>
            }
          />
          <Route
            path="/surveys/edit/:surveyId"
            element={
              <Suspense fallback={<LoginFallback />}>
                <PermissionWrapper requiredPermission={11}>
                  <EditSurveys />
                </PermissionWrapper>
              </Suspense>
            }
          />
          <Route
            path="/surveys/create"
            element={
              <Suspense fallback={<LoginFallback />}>
                <PermissionWrapper requiredPermission={11}>
                  <CreateSurvey />
                </PermissionWrapper>
              </Suspense>
            }
          />
          <Route path="/activitiesxy" element={<Activities />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
